import React from 'react';
import { css } from 'emotion';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';

import logo from '../../assets/images/logo-white.svg';
import defaultOGimage from '../../assets/images/pratap-social.jpg';

// Components
import CtaItem from '../Footer/CtaItem';
import CtaList from '../Footer/CtaList';
import Footer from '../Footer/Footer';
import Header from '../Common/Header';
import PageHero from '../Common/PageHero';

const LayoutPage = ({ data, children, title, optionsData, siteMeta }) => {
  const cssContentWrapper = css`
    margin: 0;
  `;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>{data.yoast.title || siteMeta.title}</title>
        <meta
          name="description"
          content={
            data.yoast.metadesc.trim() !== ''
              ? data.yoast.metadesc
              : siteMeta.siteMetadata.description
          }
        />
        <meta property="og:title" content={data.yoast.opengraph_title} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={data.yoast.opengraph_description} />
        <meta property="og:url" content={data.link} />
        <meta
          property="og:image"
          content={
            data.yoast.opengraph_image
              ? `http://cms.pratappartnership.co.uk/wp-content/uploads/${data.yoast.opengraph_image}`
              : `http://pratappartnership.co.uk${defaultOGimage}`
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={data.yoast.twitter_description} />
        <meta name="twitter:title" content={data.yoast.twitter_title} />
        <meta
          name="twitter:image"
          content={
            data.yoast.opengraph_image
              ? `http://cms.pratappartnership.co.uk/wp-content/uploads/${data.yoast.opengraph_image}`
              : `http://pratappartnership.co.uk${defaultOGimage}`
          }
        />
      </Helmet>

      <CssBaseline />

      <Header logo={logo} />

      <PageHero
        bgColor={themeVars.root.secondary}
        bgImage={data.acf.page_hero_bg.localFile.childImageSharp.fluid.src}
        subtitle={data.acf.page_hero_subtitle}
        title={data.acf.page_hero_title.length ? data.acf.page_hero_title : data.title}
        url={data.acf.home_hero_intro_btn_page}
      />

      <main className={cssContentWrapper}>{children}</main>

      <CtaList>
        <CtaItem
          bgColor={themeVars.root.secondary}
          label={optionsData.options.options_rescue.panel_one_label}
          title={optionsData.options.options_rescue.panel_one_title}
          to={optionsData.options.options_rescue.panel_one_page.post_name}
        />
        <CtaItem
          bgColor={themeVars.root.secondaryDark}
          label={optionsData.options.options_rescue.panel_two_label}
          title={optionsData.options.options_rescue.panel_two_title}
          to={optionsData.options.options_rescue.panel_two_page.post_name}
        />
      </CtaList>

      <Footer />
    </>
  );
};

LayoutPage.defaultProps = {
  data: null,
  title: null,
};

LayoutPage.propTypes = {
  children: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
  title: PropTypes.string,
  optionsData: PropTypes.shape({}).isRequired,
};

export default LayoutPage;
