import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import LayoutPage from '../components/Layouts/LayoutPage';
import ContentModules from '../components/ContentModules/ContentModules';

export const pageQuery = graphql`
  query($id: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }

    wordpressPage(wordpress_id: { eq: $id }, status: { eq: "publish" }) {
      yoast {
        title
        metadesc
        linkdex
        metakeywords
        meta_robots_noindex
        meta_robots_nofollow
        meta_robots_adv
        canonical
        redirect
        opengraph_title
        opengraph_description
        opengraph_image
        twitter_title
        twitter_description
      }

      link
      title
      acf {
        page_hero_title
        page_hero_subtitle
        page_hero_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 75, toFormat: JPG) {
                tracedSVG
                sizes
                src
                srcSet
                aspectRatio
              }
            }
          }
        }

        content_modules_page {
          __typename
          ... on WordPressAcf_register {
            title
            subtitle
          }
          ... on WordPressAcf_quote {
            quote
            bg_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1440, fit: FILL, quality: 75, toFormat: JPG) {
                    tracedSVG
                    sizes
                    src
                    srcSet
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on WordPressAcf_content_image {
            title
            subtitle
            layout
            content
            button
            button_label
            button_label_2
            button_label_3
            button_url_type
            button_url_type_2
            button_url_type_3
            button_url_page
            button_url_page_2
            button_url_page_3
            button_url_type_2
            button_url_type_3
            button_url_external
            button_url_external_2
            button_url_external_3
            image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 960
                    maxHeight: 800
                    quality: 80
                    fit: COVER
                    toFormat: JPG
                    cropFocus: CENTER
                  ) {
                    tracedSVG
                    sizes
                    src
                    srcSet
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on WordPressAcf_downloads {
            title
            subtitle
            downloads {
              title
              description
              file {
                source_url
              }
            }
          }
          ... on WordPressAcf_video {
            youtubeurl
          }
          ... on WordPressAcf_fourcol {
            title
            subtitle
            columns {
              title
              content
            }
          }
          ... on WordPressAcf_text {
            title
            content
          }
          ... on WordPressAcf_imageslider {
            title
            content
            images {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, fit: FILL, quality: 75, toFormat: JPG) {
                      tracedSVG
                      sizes
                      src
                      srcSet
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_frequentlyasked {
            title
            content
            faqs {
              question
              answer
            }
          }
          ... on WordPressAcf_opportunities {
            title
            jobs {
              title
              content
              link
            }
          }
          ... on WordPressAcf_team_members {
            title
            subtitle
            members {
              profile_link_type
              profile_url
              profile_url_external
              job_title
              member {
                post_title
                acf {
                  image {
                    alt_text
                    localFile {
                      childImageSharp {
                        fluid(
                          maxWidth: 255
                          maxHeight: 255
                          quality: 75
                          fit: COVER
                          toFormat: JPG
                          cropFocus: CENTER
                        ) {
                          tracedSVG
                          sizes
                          src
                          srcSet
                          aspectRatio
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        options_rescue {
          panel_one_title
          panel_one_label
          panel_one_page {
            post_name
          }
          panel_two_title
          panel_two_label
          panel_two_page {
            post_name
          }
        }
      }
    }
  }
`;

const Post = ({ data }) => {
  // Scroll to the top of the page on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <LayoutPage
      data={data.wordpressPage}
      optionsData={data.wordpressAcfOptions}
      siteMeta={data.site}
    >
      <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Row nogutter>
          <Col xs={12}>
            <Row>
              <Col>
                {data.wordpressPage.acf.content_modules_page.map((module) => {
                  return <ContentModules key={shortid.generate()} module={module} />;
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </LayoutPage>
  );
};

export default Post;

Post.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
