import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

// Components
// -----
import Title from '../Common/Title';
import Content from '../Common/Content';

const PageHero = ({ title, subtitle, bgImage, bgColor }) => {
  const cssWrapper = css`
    position: relative;
    overflow: hidden;
    
    padding-top: 22rem;
    padding-bottom: 10rem;
    background-color: ${bgColor};

    &:before {
      content: '';
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      background-image: url('${bgImage}');
      background-size: cover;
      background-position: center center;
      opacity: .15;
    }

    ${breakup.medium`
      padding-top: 28rem;
    `}
  `;

  const cssZ = css`
    ${breakup.large`
      position: relative;
      z-index: 900;
    `}
  `;

  return (
    <section className={cssWrapper}>
      <Container fluid className={cssZ}>
        <Row>
          <Col sm={12} md={8} push={{ md: 1 }} lg={5}>
            <Title size={1} color={themeVars.root.light}>
              {title}
            </Title>
            {subtitle && <Content color={themeVars.root.light} content={subtitle} />}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

// Props
// -----
PageHero.defaultProps = {
  subtitle: null,
  bgColor: themeVars.root.secondary,
};

PageHero.propTypes = {
  /** The background colour of the Hero */
  bgColor: PropTypes.string,

  /** The backgroud image of the Hero */
  bgImage: PropTypes.string.isRequired,

  /** The title of the Hero */
  title: PropTypes.string.isRequired,

  /** The subtitle of the Hero */
  subtitle: PropTypes.string,
};

export default PageHero;
